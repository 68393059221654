import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'

import ClearIcon from '@material-ui/icons/Clear'
import OrderModal from 'utils/UI/Modal/OrderModal'
import Popup from 'components/Popup'
import LoadingSpinner from 'components/LoadingSpinner'

import { processedOrdersRequest } from 'axios/requests/processedOrders'
import { orderBrokenLimitsRequest } from 'axios/requests/orderBrokenLimits'
import { acceptOrderRequest } from 'axios/requests/acceptOrder'
import { cancelOrderRequest } from 'axios/requests/cancelOrder'
import { processedOrderExcelDownloadRequest } from 'axios/requests/processedOrderExcelDownload'

import { FilterSelectors } from 'components/FilterSelectors'

import { useAccount } from '@azure/msal-react'
import NoData from 'utils/UI/NoData/NoData'

const columns = [
  {
    id: 'date',
    label: 'Fecha',
    align: 'left',
    format: (value) => {
      return moment(value).format('YYYY-MM-DD')
    }
  },
  {
    id: 'codigo_ord',
    label: 'Codigo Orden',
    align: 'right'
  },
  {
    id: 'fondo',
    label: 'Fondo',
    align: 'left'
  },
  {
    id: 'estado',
    label: 'Estado',
    align: 'left'
  },
  {
    id: 'side',
    label: 'Side',
    align: 'center'
  },
  {
    id: 'nemotecnico',
    label: 'Nemotecnico',
    align: 'left'
  },
  {
    id: 'tipo_reajuste',
    label: 'Moneda',
    align: 'left'
  },
  {
    id: 'nominal_ord',
    label: 'Nominal',
    format: (value) => parseFloat(value.toFixed(2)).toLocaleString('de-DE'),
    align: 'right'
  },
  {
    id: 'monto_ins',
    label: 'Monto Instrumento',
    format: (value) => parseFloat(value.toFixed(2)).toLocaleString('de-DE'),
    align: 'right'
  },
  {
    id: 'monto_car',
    label: 'Monto Cartera',
    format: (value) => parseFloat(value.toFixed(2)).toLocaleString('de-DE'),
    align: 'right'
  },
  {
    id: 'precio_cartera',
    label: 'Precio',
    format: (value) => parseFloat(value.toFixed(2)).toLocaleString('de-DE'),
    align: 'right'
  },
  {
    id: 'batch_id',
    label: 'ID Batch',
    align: 'left'
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: '75vh'
  },
  tableRow: {
    height: 60,
    border: '1px solid'
  },
  tableCell: {
    height: 'inherit',
    maxHeight: 200,
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignContent: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  tableStateCell: {
    display: 'flex',
    border: 'hidden',
    maxHeight: 200,
    maxWidth: 200,
    alignItems: 'center'
  },
  buttonCell: {
    height: 'inherit',
    maxHeight: 200,
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  acceptBtn: {
    color: theme.palette.primary.main
  },
  rejectBtn: {
    color: theme.palette.error.main
  },
  downloadBtn: {
    color: theme.palette.primary.main,
    maxHeight: 180
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const Orders = ({ match, location }) => {
  const classes = useStyles()
  const account = useAccount()
  const accountRole = account ? account.idTokenClaims.roles[0] : ''
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [totalOrders, setTotalOrders] = useState(0)

  const [records, setRecords] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [processedOrder, setProcessedOrder] = useState(null)

  const [openJustificationPopUp, setOpenJustificationPopUp] = useState(false)

  const { orderIdParam } = useParams()
  const filters = JSON.parse(sessionStorage.getItem('ordersFilters'))
  const [filterParams, setFilterParams] = useState(
    orderIdParam
      ? { codigo_ord: orderIdParam, estado: 'Pre-anulada' }
      : filters || { estado: 'Pre-anulada' })

  const [justificationOrder, setJustificationOrder] = useState(null)
  const [justificationAction, setJustificationAction] = useState(null)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  useEffect(() => {
    let mounted = true
    if (mounted) {
      fetchOrders()
    }
    return () => { mounted = false }
  }, [filterParams])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    fetchOrders(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPage(0)
    setRowsPerPage(+event.target.value)
    fetchOrders(0, +event.target.value)
  }

  const handlePopUpOpen = (event, order, action) => {
    setJustificationOrder(order)
    setJustificationAction(action)
    setOpenJustificationPopUp(true)
  }

  const fetchOrders = async (currentPage = page, pageSize = rowsPerPage) => {
    try {
      setLoading(true)
      const result = await processedOrdersRequest(
        {
          ...filterParams,
          page: 1 + currentPage,
          page_size: pageSize
        }
      )
      setLoading(false)
      const newRecords = result.data.results
      setRecords(newRecords)
      setTotalOrders(result.data.count)
    } catch (err) {
      console.log(err)
    }
  }

  const handleRequestSubmit = async () => {
    let response = null
    if (justificationAction === 'aceptar') {
      response = await acceptOrderRequest(justificationOrder.codigo_ord)
    } else {
      response = await cancelOrderRequest(justificationOrder.codigo_ord)
    }
    if (response) {
      await fetchOrders()
    }
  }

  const handleModalOpen = async (event, row) => {
    const response = await orderBrokenLimitsRequest(row.id)
    const limits = response.data
    setModalData(limits)
    setProcessedOrder(row)
    setOpenModal(true)
  }

  const handleModalClose = (event) => {
    setOpenModal(false)
    setModalData(null)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleProcessedOrderExcelDownload = async (orderToDownload) => {
    try {
      const params = orderToDownload.batch_id
        ? { batch_id: orderToDownload.batch_id }
        : { order_code: orderToDownload.codigo_ord }

      const response = await processedOrderExcelDownloadRequest(params)

      // Get the filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition']
      const filenameMatch = contentDisposition.match(/filename="(.+)"/)
      const filename = filenameMatch ? filenameMatch[1] : 'downloaded_file.xlsx' // Default filename if not found

      const blob = new Blob(
        [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      )
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Paper className={classes.root}>
      <OrderModal open={openModal} handleClose={handleModalClose} limits={modalData} processedOrder={processedOrder} />
      <Popup
        openJustificationDialog={openJustificationPopUp}
        setOpenJustificationDialog={setOpenJustificationPopUp}
        handleRequestSubmit={handleRequestSubmit}
        order={justificationOrder}
        action={justificationAction}
      />
      <FilterSelectors
        setFilterParams={setFilterParams}
        initialOrderId={orderIdParam || ''}
        fetchOrders={fetchOrders}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {loading && <LoadingSpinner headerLength={columns.length + 1} />}
          <TableBody>
            {records.length > 0
              ? (records.slice().sort(getComparator(order, orderBy)).map((row, index) => (
                <TableRow
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.codigo_ord}
                  className={classes.tableRow}
                >
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (column.id === 'estado' && row.broke_limits) {
                      return (
                        <TableCell
                          key={column.id + index}
                          align={column.align}
                          className={classes.tableCell}
                          onClick={row.broke_limits ? (event) => handleModalOpen(event, row) : null}
                        >
                          <div className={classes.tableStateCell}>
                            {column.format ? column.format(value) : value}
                            <ErrorOutlineIcon />
                          </div>
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell
                          key={column.id + index}
                          align={column.align}
                          className={classes.tableCell}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      )
                    }
                  })}
                  <TableCell align='center' className={classes.tableCell}>
                    <IconButton
                      onClick={(event) => handleProcessedOrderExcelDownload(row)}
                      variant='contained'
                      className={classes.downloadBtn}
                    >
                      <GetAppIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    {(row.estado === 'Pre-anulada' && accountRole === 'director')
                      ? (
                        <>
                          <IconButton
                            onClick={(event) => handlePopUpOpen(event, row, 'aceptar')}
                            variant='contained'
                            className={classes.acceptBtn}
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            onClick={(event) => handlePopUpOpen(event, row, 'cancelar')}
                            variant='contained'
                            className={classes.rejectBtn}
                          >
                            <ClearIcon />
                          </IconButton>
                        </>)
                      : null}
                  </TableCell>
                </TableRow>
                )))
              : (!loading && <NoData />)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component='div'
        count={totalOrders}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort
  } = props
  const classes = useStyles()
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                  <Box className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                  )
                : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key='descargar' align='center'>
          Descargar
        </TableCell>
        <TableCell key='acciones' align='center'>
          Acciones
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default Orders
